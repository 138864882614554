/* eslint-disable */
export default function init() {
  if (jQuery(".c-header").attr("data-mode") !== "dark") {
    let deltaInit = 300;

    jQuery(window).on("scroll", function () {
      let scroll = jQuery(window).scrollTop();

      if (scroll > deltaInit) {
        jQuery(".c-header").addClass("scrolled").attr("data-mode", "dark");
      } else {
        jQuery(".c-header").removeClass("scrolled").attr("data-mode", "light");
      }
    });
  }

  jQuery("#menu-header-principal-nav > li.menu-item").hover(
    function () {
      if (jQuery(this).hasClass("menu-item-has-children")) {
        jQuery(".c-header").addClass("scrolled");
        jQuery(".sub-menu-wrapper").removeClass("open");
        jQuery(this).children(".sub-menu-wrapper").addClass("open");
      } else {
        jQuery(".c-header").removeClass("scrolled");
        jQuery(".sub-menu-wrapper").removeClass("open");
      }
    },
    function () {
    }
  );

  jQuery("#menu-header-principal-nav .sub-menu-wrapper").on(
    "mouseleave",
    function () {

      jQuery(".c-header").removeClass("scrolled");
      jQuery(".sub-menu-wrapper").removeClass("open");
    }
  );
}
