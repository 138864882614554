/* eslint-disable */
export default function init() {

  if($('.nav-categories').length == 0) return;

  const scrollDuration = 300;
  const leftPaddle = document.getElementsByClassName('left-paddle');
  const rightPaddle = document.getElementsByClassName('right-paddle');

  const contenedor = $('.menu_container');
  var contenedorOffset = contenedor.offset().left;
  let elementos = contenedor.find('.link');

  let posElementos = [];
  // Calcular las posiciones relativas de los elementos en relación con el contenedor
  elementos.each(function() {
    let data = [];
    data['left'] = $(this).offset().left - contenedorOffset;
    data['width'] = $(this).width();

    posElementos.push(data);    
  });

  $('window').on('resize',function(){
    elementos = $('.menu_container').find('.link');

    posElementos = [];
    // Calcular las posiciones relativas de los elementos en relación con el contenedor
    elementos.each(function() {
      let data = [];
      data['left'] = $(this).offset().left;
      data['width'] = $(this).width();

      posElementos.push(data);    
    });
  })

  // scroll to right
  $(rightPaddle).on('click', function () {
    var scrollDerecha = contenedor.scrollLeft();
    for (var i = 0; i < posElementos.length; i++) {
      let pos_element = posElementos[i]['left'] + posElementos[i]['width'];

      if (pos_element > contenedor.width() + scrollDerecha) {
        contenedor.animate({ scrollLeft: posElementos[i]['left'] }, scrollDuration);
        break;
      }
    }
  });

  // scroll to left
  $(leftPaddle).on('click', function () {
    var scrollIzquierda = contenedor.scrollLeft();
    for (var i = posElementos.length - 1; i >= 0; i--) {
      if (posElementos[i]['left'] < scrollIzquierda) {
        var scroll_right = posElementos[i]['left'] - contenedor.width() + posElementos[i]['width'];
        contenedor.animate({ scrollLeft: scroll_right}, scrollDuration);
        break;
      }
    }
  });

  const nav_active = contenedor.find('.link.active');
  if(nav_active.length > 0){
    var posicion = nav_active.position().left - contenedorOffset;
    contenedor.scrollLeft(posicion);
  }
}