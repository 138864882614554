/* eslint-disable */
export default function init() {
  let contacto = jQuery('*[data-id="contacto"]');
  let empresa = jQuery('*[data-id="empresa"]');
  let actividad = jQuery('*[data-id="actividad"]');

  let fieldName = jQuery("#username");
  let fieldEmail = jQuery("#email");
  let fieldPhone = jQuery("#phone");

  let fieldCompany = jQuery("#company");
  let fieldIndustria = jQuery("#industria");
  let fieldPuntorecogida = jQuery("#puntorecogida");
  let fieldUbicacion = jQuery("#ubicacion");
  let fieldPais = jQuery("#pais");
  let fieldCity = jQuery("#city");

  jQuery(".js-form-step1").on("click", function () {
    if (validateFormStep1()) {
      jQuery("#formstep").val("empresa");
      contacto.addClass("wpcf7cf-hidden").hide();
      empresa.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(2)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top - 74
      }, 1000);
    }
  });

  jQuery(".js-form-step2").on("click", function () {
    if (validateFormStep2()) {
      jQuery("#formstep").val("actividad");
      empresa.addClass("wpcf7cf-hidden").hide();
      actividad.removeClass("wpcf7cf-hidden").show();
      jQuery(".form-items-steps p").removeClass("active");
      jQuery(".form-items-steps p:nth-child(3)").addClass("active");

      let form = jQuery(this).closest('.block-form');
      $([document.documentElement, document.body]).animate({
        scrollTop: jQuery(form).offset().top -74
      }, 1000);
    }
  });

  jQuery(".js-back-form-step1").on("click", function () {
    jQuery("#formstep").val("contacto");
    contacto.addClass("wpcf7cf-hidden").show();
    empresa.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(1)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top - 74
    }, 1000);
  });

  jQuery(".js-back-form-step2").on("click", function () {
    jQuery("#formstep").val("empresa");
    empresa.addClass("wpcf7cf-hidden").show();
    actividad.removeClass("wpcf7cf-hidden").hide();
    jQuery(".form-items-steps p").removeClass("active");
    jQuery(".form-items-steps p:nth-child(2)").addClass("active");

    let form = jQuery(this).closest('.block-form');
    $([document.documentElement, document.body]).animate({
      scrollTop: jQuery(form).offset().top -74
    }, 1000);
    
  });

  jQuery('.block-form select').on('change', function() {
    if (jQuery(this).val() != '') {
      jQuery(this).addClass('selected');
    } else {
      jQuery(this).removeClass('selected');
    }
  });

  function validateFormStep1() {
    if (fieldName.val() === "") {
      showError(fieldName);
    }
    else{
      removeError(fieldName);
    }

    if (fieldPhone.val() === "") {
      showError(fieldPhone);
    }
    else{
      removeError(fieldPhone);
    }

    if (IsEmail(fieldEmail.val()) === false) {
      showError(fieldEmail);
    }
    else{
      removeError(fieldEmail);
    }

    if (fieldName.val() !== "" && fieldPhone.val() !== "" && IsEmail(fieldEmail.val()) !== false) {
      return true;
    }

    return false;
  }

  function validateFormStep2() {
    if (fieldCompany.val() === "") {
      showError(fieldCompany);
    }
    else{
      removeError(fieldCompany);
    }

    if (fieldIndustria.val() === "") {
      showError(fieldIndustria);
    }
    else{
      removeError(fieldIndustria);
    }

    if (fieldPuntorecogida.val() === "") {
      showError(fieldPuntorecogida);
    }
    else{
      removeError(fieldPuntorecogida);
    }

    if (fieldUbicacion.val() === "") {
      showError(fieldUbicacion);
    }
    else{
      removeError(fieldUbicacion);
    }

    if (fieldPais.val() === "") {
      showError(fieldPais);
    }
    else{
      removeError(fieldPais);
    }

    let fieldProvince = jQuery('.state_select.show .wpcf7-state_select');

    if (fieldProvince.val() === "") {
      showError(fieldProvince);
    }
    else{
      removeError(fieldProvince);
    }

    let fieldCity = jQuery('.city_select.show .wpcf7-city_select');

    if (fieldCity.val() === "") {
      showError(fieldCity);
    }
    else{
      removeError(fieldCity);
    }

    if (
      fieldCompany.val() !== "" &&
      fieldIndustria.val() !== "" &&
      fieldPuntorecogida.val() !== "" &&
      fieldUbicacion.val() !== "" &&
      fieldPais.val() !== "" &&
      fieldProvince.val() !== "" &&
      fieldCity.val() !== ""
    ) {
      return true;
    }

    return false;
  }

  function showError(field) {
    if(!field.hasClass("wpcf7-not-valid")){
      field.addClass("wpcf7-not-valid");

      const lang = $('html').attr('lang');
      if(lang == 'en-US') {
        field.after(
          '<span class="wpcf7-not-valid-tip" aria-hidden="true">Please fill in this field or validate that it is correct.</span>'
        );
      }
      else {
        field.after(
          '<span class="wpcf7-not-valid-tip" aria-hidden="true">Por favor, rellena este campo o valida que es correcto.</span>'
        );
      }
    }
  }

  function removeError(field) {
    if(field.hasClass("wpcf7-not-valid")){
      field.removeClass("wpcf7-not-valid");
      field.next('.wpcf7-not-valid-tip').remove();
    }
  }

  function IsEmail(email) {
    var regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regex.test(email)) {
      return false;
    } else {
      return true;
    }
  }
}
