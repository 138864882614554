$(function() {
  $('.lity-custom-close').on('click',function(){    
      $('.lity-close').trigger('click');
  });

  $(document).on('lity:open',function() {
      if ($('.custom-close')) {            
          $('.lity-close').hide();
      }
  });
});