// import external dependencies
/* eslint-disable */
import "jquery";

// Import everything from autoload
;

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import error404 from "./routes/error";
import singlePost from "./routes/blogpost";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // About Us page, note the change from about-us to aboutUs.
  error404,
  //Blog
  singlePost
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
