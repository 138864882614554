/* eslint-disable */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import Lenis from "@studio-freight/lenis";

export default function init() {
    if(jQuery('.orangeWrapper').length == 0){
      return;
    }
    
    // JavaScript to be fired on the home page
    gsap.registerPlugin(ScrollTrigger);

    const splitTypes = document.querySelectorAll(".reveal-type");

    splitTypes.forEach((word, i) => {
      const bg = word.dataset.bgColor;
      const fg = word.dataset.fgColor;

      const text = new SplitType(word, { types: "words" });

      gsap.fromTo(
        text.words,
        {
          color: bg,
        },
        {
          color: fg,
          duration: 0.8,
          stagger: 0.05,
          scrollTrigger: {
            trigger: word,
            start: "50% 50%",
            end: "+=200%",
            scrub: true,
            markers: false,
            toggleActions: "play play reverse reverse",
          },
        }
      );
    });

    const lenis = new Lenis();
    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    
    requestAnimationFrame(raf);

    const tl = gsap
    .timeline({
      scrollTrigger: {
        trigger: ".orangeWrapper",
        scrub: true,
        pin: true,
        start: "50% 50%",
        end: "+=200%",
        onUpdate: function(self){
          if(self.progress > 0.85){
            jQuery('.orangeWrapper').addClass("show_video");
          }
          else {
            jQuery('.orangeWrapper').removeClass("show_video");
          }
        }
      },
    })

    jQuery('.orangeWrapper').on('click',function(){
      let orangeWrapper = jQuery(this);

      if(orangeWrapper.hasClass('show_video')){
        let video = orangeWrapper.find('video');

        if(orangeWrapper.hasClass('playing')){
          video[0].pause();
          orangeWrapper.removeClass('playing');
        }
        else {
          video[0].play();
          orangeWrapper.addClass('playing');
        }
      }
    })
};