/* eslint-disable */

export default function init() {
  // Dropdown
  jQuery(".accordion-link").on("click", function () {
    let id = jQuery(this).data("reference");
    let iconPlus = jQuery(this).find("svg path.svg-plus");
    let iconMinus = jQuery(this).find("svg path.svg-minus");

    let contents = jQuery('.accordion-content:not('+ id +')');
    contents.each(function(){
      jQuery(this).slideToggle(200).addClass("hidden").hide();

      let parent = jQuery(this).parent()
      let iconPlusItem = parent.find("svg path.svg-plus");
      let iconMinusItem = parent.find("svg path.svg-minus")

      jQuery(iconMinusItem).removeClass("opacity-1");
      jQuery(iconMinusItem).addClass("opacity-0");
      jQuery(iconPlusItem).removeClass("opacity-0");
      jQuery(iconPlusItem).addClass("opacity-1");
    })

    if (jQuery(id).hasClass("hidden")) {
      jQuery(id).slideToggle(200).removeClass("hidden");

      jQuery(iconPlus).removeClass("opacity-1");
      jQuery(iconPlus).addClass("opacity-0");
      jQuery(iconMinus).removeClass("opacity-0");
      jQuery(iconMinus).addClass("opacity-1");
    } else {
      jQuery(id).slideToggle(200).addClass("hidden");

      jQuery(iconMinus).removeClass("opacity-1");
      jQuery(iconMinus).addClass("opacity-0");
      jQuery(iconPlus).removeClass("opacity-0");
      jQuery(iconPlus).addClass("opacity-1");
    }
  });

  // Read more normal
  jQuery(".toggle-button").on("click", function (e) {
    e.preventDefault();

    const buttonText = jQuery(this).text().trim();
    const textOpen = jQuery(this).data("open");
    const textClose = jQuery(this).data("close");

    jQuery(this).prev(".hidden-content").slideToggle(200);
    jQuery(this).next().toggleClass("rotate-180");
    jQuery(this).html(buttonText === textOpen ? textClose : textOpen);
  });

  // Read more normal
  jQuery(".toggle-button-reverse").on("click", function (e) {
    e.preventDefault();

    const buttonText = jQuery(this).text().trim();
    const textOpen = jQuery(this).data("open");
    const textClose = jQuery(this).data("close");

    jQuery(this).next().next(".hidden-content").slideToggle(200);
    jQuery(this).next().toggleClass("rotate-180");
    jQuery(this).html(buttonText === textOpen ? textClose : textOpen);
  });

  jQuery(".toggle-button, .toggle-button-reverse").hover(function () {
    jQuery(this).next().toggleClass("text-neutrals-700");
  });

  // Accordion
  jQuery(function () {
    // Cache selectors
    var $accordion = $(".js-accordion");
    var $allPanels = $(".accordion-panel").hide();
    var $allItems = $(".accordion-item");

    // Event listeners
    $accordion.on("click", ".accordion-toggle", function () {
      // Toggle the current accordion panel and close others

      $allPanels.slideUp();
      $allItems.removeClass("faq-is-open");

      if (jQuery(this).next().is(":visible")) {
        jQuery(".accordion-panel").slideUp();
        jQuery(".accordion-icon").removeClass("rotate-180");
      } else {
        jQuery(this)
          .next()
          .slideDown()
          .closest(".accordion-item")
          .addClass("faq-is-open");
        jQuery(this).children("svg.accordion-icon").addClass("rotate-180");
      }
      return false;
    });
  });

  // Forms
  jQuery("#flexCheckIndeterminate").prop("indeterminate", true);
  jQuery("#flexCheckIndeterminateDisabled").prop("indeterminate", true);

  // Tabs
  document
    .querySelectorAll("[data-tabs-toggle]")
    .forEach(function (tabsToggleEl) {
      const tabsToggleElementsId = tabsToggleEl.getAttribute("id");
      const tabsToggleElements = document.querySelectorAll(
        "#" + tabsToggleElementsId + ' [role="tab"]'
      );
      var activeTabToggleEl = null;
      var activeTabContentEl = null;

      tabsToggleElements.forEach(function (tabToggleEl) {
        tabToggleEl.addEventListener("click", function (event) {
          var tabToggleEl = event.target;
          var tabTargetSelector = tabToggleEl.getAttribute("data-tabs-target");
          var tabContentEl = document.querySelector(tabTargetSelector); // don't do anything if it's already active

          if (tabToggleEl !== activeTabToggleEl) {
            // find currently active tab toggle and content if not set
            if (!activeTabToggleEl && !activeTabContentEl) {
              activeTabToggleEl = document.querySelector(
                "#" + tabsToggleElementsId + ' [aria-selected="true"]'
              );
              activeTabContentEl = document.querySelector(
                activeTabToggleEl.getAttribute("data-tabs-target")
              );
            } // show and activate tab

            tabToggleEl.classList.add("active");
            tabToggleEl.setAttribute("aria-selected", true);
            tabContentEl.classList.remove("hidden"); // hide and deactive currently active tab toggle and content

            activeTabToggleEl.setAttribute("aria-selected", false);
            activeTabToggleEl.classList.remove("active");
            activeTabContentEl.classList.add("hidden"); // set currently active toggle and content tabs

            activeTabToggleEl = tabToggleEl;
            activeTabContentEl = tabContentEl;
          }
        });
      });
    });

  jQuery(window).on("load", function () {
    function goToByScroll(id) {
      jQuery("html, body").animate(
        {
          scrollTop: jQuery("#" + id).offset().top - 10,
        },
        1000
      );
    }
    if (window.location.hash != "") {
      goToByScroll(window.location.hash.substr(1));
    }
  });
}
