/* eslint-disable */
export default function init() {
  if (jQuery(".js-playvideo").height) {
    jQuery(".js-playvideo").on("click", function () {
      let that = jQuery(this);
      that.addClass("hidden");
      const js_video = jQuery(this).next(".js-video");

      if(js_video.hasClass('local')){
        const video = js_video.find('video')[0];
        video.play();

        video.addEventListener("click", function(e) {
          // Reanudar la reproducción del video si estaba reproduciéndose antes de hacer clic en la barra de progreso
          if (!video.paused) {
              video.play();
          }
      });
      }
      else if(js_video.hasClass('vimeo')){
        const iframe = js_video.find('iframe');
        const player = new Vimeo.Player(iframe);
        player.play();

        player.on('pause', function(data) {
          that.removeClass("hidden");
          js_video.addClass("hidden");
        });
      }
      
      js_video.removeClass("hidden");
    });
  }

  jQuery(".ml-video").magnificPopup({
    preloader: true,
    type: "iframe",
    iframe: {
      patterns: {
        youtube: {
          index: "youtube.com/",
          id: "v=",
          src: "//www.youtube.com/embed/%id%?enablejsapi=1&autoplay=1&rel=0", // set enablejsapi to 1
        },
      },
      srcAction: "iframe_src",
    },
  });
}
