/* eslint-disable */
export default function init() {
  const form = $('.wpcf7-form');
  if(form.length == 0) return;

  const country = form.find('select#pais');
  const states = form.find('.state_select');
  const states_select = form.find('.wpcf7-state_select');

  const cities = form.find('.city_select');
  const cities_select = cities.find('.wpcf7-city_select');

  var select_cities_es = form.find('.city_select_es select');
  var options_cities_es = select_cities_es.html();
  var select_cities_fr = form.find('.city_select_fr select');
  var options_cities_fr = select_cities_fr.html();
  var select_cities_pt = form.find('.city_select_pt select');
  var options_cities_pt = select_cities_pt.html();
  var select_cities_it = form.find('.city_select_it select');
  var options_cities_it = select_cities_it.html();

  country.on('change',function(){
    let country_code = $(this).val();

    if(country_code == 'Spain' || country_code == 'España'){
      country_code = 'es';
    }
    else if(country_code == 'Italy' || country_code == 'Italia'){
      country_code = 'it';
    }
    else if(country_code == 'France' || country_code == 'Francia'){
      country_code = 'fr';
    }
    else if(country_code == 'Portugal'){
      country_code = 'pt';
    }

    states.removeClass('show');
    states_select.val('');
    cities.removeClass('show');
    cities_select.val('');

    if(country_code != ""){
      let states = form.find('.state_select_' + country_code);
      states.addClass('show');
    }
  })

  states_select.on('change', function(){
    var selectedValue = $(this).val();

    cities.removeClass('show');
    cities_select.val('');

    let country_code = country.val();
    if(country_code == 'Spain' || country_code == 'España'){
      country_code = 'es';
      select_cities_es.html(options_cities_es);
    }
    else if(country_code == 'Italy' || country_code == 'Italia'){
      country_code = 'it';
      select_cities_it.html(options_cities_it);
    }
    else if(country_code == 'France' || country_code == 'Francia'){
      country_code = 'fr';
      select_cities_fr.html(options_cities_fr);
    }
    else if(country_code == 'Portugal'){
      country_code = 'pt';
      select_cities_pt.html(options_cities_pt);
    }

    if(country_code != "" && selectedValue != ""){
      let cities_selected = form.find('.city_select_' + country_code);
      cities_selected.addClass('show');
      const first_option = cities_selected.find('option:first')
      const first_option_text = first_option.text();
      first_option.text('Loading...');

      setTimeout(function(){
        get_city_select(cities_selected, selectedValue);
        first_option.text(first_option_text);
      }, 0);
    }
  })
}

function get_city_select(cities, state){
    let select_cities = cities.find('.wpcf7-city_select');
    select_cities.find('option:not([data-state="'+ state +'"]):not(:first)').remove();
}