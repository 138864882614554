/* eslint-disable */
import 'lity/dist/lity';
import '../modules/modal';

export default {
  init() {
    // JavaScript to be fired on the about us page
    jQuery(".c-header").attr("data-mode", "dark");
  },
};
