/* eslint-disable */
import L from "leaflet";
import "leaflet-search";
import "leaflet.markercluster";

export default function init() {
  if(jQuery('#map').length == 0) return;

  let dirPath = window.location.origin;

  var limitesEuropa = L.latLngBounds(
    L.latLng(35, -24), // Latitud, Longitud del suroeste de Europa
    L.latLng(53, 21) // Latitud, Longitud del noreste de Europa
  );

  // config map
  let config = {
    minZoom: 5.2,
    maxZoom: 9,
    preferCanvas: true,
    zoomControl: false,
  };

  const zoom = 5.2;
  const lat = 44.43513228190882;
  const lng = -0.9057117782019052;

  const map = L.map("map", config).setView([lat, lng], zoom);
  map.fitBounds(limitesEuropa);
  map.setMaxBounds(limitesEuropa);
  map.on("drag", function () {
    map.panInsideBounds(limitesEuropa, { animate: false });
  });

  // primer array abajo izquierda.
  // segundo array arriba derecha. 
  // 1º cuanto mas alto más arriba. 2º cuanto mas bajo más izquierda
  var latLngBounds = L.latLngBounds([
    [34.15, -31.35], // abajo izquierda
    [53.88, 28.93], // arriba derecha
  ]);

  L.imageOverlay(
    dirPath + "/wp-content/themes/paack/resources/assets/images/map/map2.svg",
    latLngBounds,
    {
      opacity: 1,
      interactive: false,
    }
  ).addTo(map);

  var allPoints = [];

  function fetchLocations() {
    $.ajax({
      type : "GET",
      dataType : "json",
      url : "/wp-admin/admin-ajax.php",
      data : {action: "get_locations"},
      success: function(data) {

        if (data.length > 0) {
          data.forEach((val) => {
            if(val.latitud != null && val.longitud != null){
              allPoints.push({
                name: val.title,
                ciudad: val.ciudad,
                pais: val.pais,
                lat: val.latitud,
                desc: val.desc,
                lng: val.longitud,
                latlng: [
                  parseFloat(val.latitud),
                  parseFloat(val.longitud),
                ],
                category: "coming",
                temperatura: val.temperatura,
                sostenible: val.sostenible,
                distribucion: val.distribucion,
                coming: val.coming,
              });
            }
          });
        }

        setMap(allPoints);

        // Manejar los checkboxes para filtrar los marcadores
        const allFilterCheckboxes = {
          distribution: document.getElementById("distributionCheckbox"),
          coming: document.getElementById("comingCheckbox"),
          temperature: document.getElementById("temperatureCheckbox"),
          sustainability: document.getElementById("sustainabilityCheckbox"),
          espana: document.getElementById("spainCheckbox"),
          francia: document.getElementById("franceCheckbox"),
          portugal: document.getElementById("portugalCheckbox"),
          italia: document.getElementById("italyCheckbox"),
        };

        const filterCheckboxesCenters = {
          distribution: allFilterCheckboxes.distribution,
          coming: allFilterCheckboxes.coming,
          temperature: allFilterCheckboxes.temperature,
          sustainability: allFilterCheckboxes.sustainability,
        };

        const filterCheckboxesCountry = {
          espana: allFilterCheckboxes.espana,
          francia: allFilterCheckboxes.francia,
          portugal: allFilterCheckboxes.portugal,
          italia: allFilterCheckboxes.italia,
        };

        // Agregar eventos de cambio a los checkboxes
        for (const checkboxId in allFilterCheckboxes) {
          if (allFilterCheckboxes.hasOwnProperty(checkboxId)) {          
            allFilterCheckboxes[checkboxId].addEventListener("change", function() {
              resetIcons(markers);

              const selectedCenters = Object.keys(filterCheckboxesCenters).filter(
                (id) => filterCheckboxesCenters[id].checked
              );

              const selectedCountries = Object.keys(filterCheckboxesCountry).filter(
                (id) => filterCheckboxesCountry[id].checked
              );

              const layers_by_country = [];

              markers.forEach((marker) => {
                let isVisible = false;

                if (selectedCenters.length === 0 && selectedCountries.length === 0) {
                  isVisible = true;
                } else {

                  let contain_center = selectedCenters.length === 0;

                  selectedCenters.forEach((center) => {

                      if (center === "temperature" && marker.temperatura) {
                        contain_center = true;
                      }

                      if (center === "sustainability" && marker.sostenible) {
                        contain_center = true;
                      }

                      if (center === "distribution" && marker.distribucion) {
                        contain_center = true;
                      }

                      if (center === "coming" && marker.coming) {
                        contain_center = true;
                      }
                  });

                  let in_country = selectedCountries.length === 0;

                  selectedCountries.forEach((country) => {
                      if (country === marker.country) {
                        in_country = true;
                      }
                  });

                  isVisible = contain_center && in_country;
                }
                if (isVisible) {
                  if (!layers_by_country[marker.country]) {
                    layers_by_country[marker.country] = [];
                  }
          
                  layers_by_country[marker.country].push(marker);
                }
              });

              allMarkerClusterGroups.clearLayers();

              Object.keys(layers_by_country).forEach(key => {
                let markerClusterGroup = L.markerClusterGroup({
                  zoomToBoundsOnClick: true,
                  showCoverageOnHover: false,
                  disableClusteringAtZoom: 7,
                  maxClusterRadius: 120
                });
        
                let country = layers_by_country[key];
        
                country.forEach((marker) => {
                  markerClusterGroup.addLayer(marker)
                })
        
                markerClusterGroup.addTo(map);
                allMarkerClusterGroups.addLayer(markerClusterGroup);
              });
            });
          }
        }

        setSearch();
        jQuery('#map-show-filters').addClass('show');
        jQuery('#searcherMap').addClass('show');
        jQuery('.legend').addClass('show');
      },
      error: function (error) {
        console.error("Error en la solicitud AJAX: ", error);
      },
    })
  }

  var allMarkerClusterGroups = L.layerGroup();

  let markers = [];

  function setMap(allPoints) {
    var LeafIcon = L.Icon.extend({
      options: {
        iconSize: [12, 12],
      },
    });

    const darkIcon = new LeafIcon({
      iconUrl: 
        dirPath + 
        "/wp-content/themes/paack/resources/assets/images/map/dark_circle.svg",
    }),
    blueIcon = new LeafIcon({
      iconUrl:
        dirPath +
        "/wp-content/themes/paack/resources/assets/images/map/blue_circle.svg",
    }),
    yellowIcon = new LeafIcon({
      iconUrl:
        dirPath +
        "/wp-content/themes/paack/resources/assets/images/map/yellow_circle.svg",
    }),
    greenIcon = new LeafIcon({
      iconUrl:
        dirPath +
        "/wp-content/themes/paack/resources/assets/images/map/green_circle.svg",
    });

    L.control
      .zoom({
        position: "bottomright",
      })
      .addTo(map);
    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png",
      {
        attribution: "&copy; Paack",
      }
    ).addTo(map);

    // Create a LayerGroup
    var valoresLeyenda = JSON.parse(document.getElementById('info-div').dataset.valores_leyenda);

    const layers_by_country = [];

    allPoints.forEach(function (item) {
      let divCirles = "";
      let icon = darkIcon;

      if (item.temperatura === true) {
        divCirles +=
          '<div class="flex gap-2"><div class="block rounded-full w-4 h-4 text-bodytext-xs font-secondary bg-background-blue"></div><p class="text-bodytext-xs">' + valoresLeyenda.azul + '</p></div>';
      }
      if (item.sostenible === true) {
        divCirles +=
          '<div class="flex gap-2"><div class="block rounded-full w-4 h-4 text-bodytext-xs font-secondary bg-background-forestgreen"></div><p class="text-bodytext-xs">' + valoresLeyenda.verde + '</p></div>';
      }
      if (item.distribucion === true) {
        divCirles +=
          '<div class="flex gap-2"><div class="block rounded-full w-4 h-4 text-bodytext-xs font-secondary bg-background-dark"></div><p class="text-bodytext-xs">' + valoresLeyenda.negro + '</p></div>';
      }
      if (item.coming === true) {
        divCirles +=
          '<div class="flex gap-2"><div class="block rounded-full w-4 h-4 text-bodytext-xs font-secondary bg-background-yellow"></div><p class="text-bodytext-xs">' + valoresLeyenda.amarillo + '</p></div>';
      }

      if (item.temperatura === true) {
        icon = blueIcon
      }
      else if (item.sostenible === true) {
        icon = greenIcon
      }
      else if (item.coming === true) {
        icon = yellowIcon
      }

      item.name = item.name.replace("&#8211;", "|");
      var marker = L.marker([item.lat, item.lng], {
        title: item.name.replace("&#8211;", "-"),
        type: item.category,
        icon: icon,
        properties: { name: item.name },
      }).bindPopup(
        '<div><a href="#" class="cursor-pointer absolute top-0 right-2 text-headline-m leaflet-popup-close-button" role="button" aria-label="Close popup"><span aria-hidden="true"></span></a><p class="tracking-wide block text-label-s mb-4">' +
        item.name +
        '</p><div class="flex gap-2"><div class="flex flex-wrap gap-2 flex-col">' +
        divCirles +
        '</div></div></div>',
        {
          closeButton: false
        }
      );
      marker.coming = item.coming;
      marker.distribucion = item.distribucion;
      marker.sostenible = item.sostenible;
      marker.temperatura = item.temperatura;
      marker.country = item.pais;

      marker.properties = {
        name: item.name.replace("&#8211;", "-"),
      };

      if (!layers_by_country[item.pais]) {
        layers_by_country[item.pais] = []; // Inicializar como un array vacío si no existe
      }

      layers_by_country[item.pais].push(marker);

      marker.category = item.category;
      markers.push(marker);
    });

    allMarkerClusterGroups.clearLayers();

    Object.keys(layers_by_country).forEach(key => {
      let markerClusterGroup = L.markerClusterGroup({
        zoomToBoundsOnClick: true,
        showCoverageOnHover: false,
        disableClusteringAtZoom: 7,
        maxClusterRadius: 120,
      });

      let country = layers_by_country[key];

      country.forEach((marker) => {
        markerClusterGroup.addLayer(marker)
      })

      markerClusterGroup.addTo(map);
      allMarkerClusterGroups.addLayer(markerClusterGroup);
    });

    markers.forEach(function (marker) {
      marker.on("click", function () {
        openMarkerModal(marker)
      });
    });

    $("#map-show-filters").on("click", function () {
      $("#map-filters").removeClass("hidden");

      if(!$('.legend').hasClass('closed') || !$('.legend').hasClass('closed_mobile')){
        $('.legend').addClass('closed_mobile');
      }
    });
    $("#map-hide-filters").on("click", function () {
      $("#map-filters").addClass("hidden");      
      $('.legend').removeClass('closed_mobile');
    });

    $('body').on('click','.leaflet-popup-close-button',function(e){
      e.preventDefault();
      document.getElementById("info-div").classList.add("opacity-0");
      document.getElementById("info-div").classList.remove("opacity-1");
      jQuery('#info-div').removeClass('marker_show');
      resetIcons(markers);
    })
  }

  function resetIcons(markers){
    markers.forEach(function (marker) {
      let icon = "";
      if (marker.temperatura === true) {
        icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/blue_circle.svg";
      }
      else if (marker.sostenible === true) {
        icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/green_circle.svg";
      }
      else if (marker.coming === true) {
        icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/yellow_circle.svg";
      }
      else {
        icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/dark_circle.svg";
      }

      marker.setIcon(
        L.icon({
          iconUrl: icon,
          iconSize: 12,
        })
      );
    });
  }

  function setLegend() {
    /* legend */
    const legend = L.control({
      position: "bottomleft",
    });
    const valoresLeyenda2 = JSON.parse(document.getElementById('info-div').dataset.valores_leyenda);
    const div = L.DomUtil.create("div", "legend");
    const color = ["#46A2DB", "#009844", "#0A253C", "#FBD259"];
    const label = [
      valoresLeyenda2.azul,
      valoresLeyenda2.verde,
      valoresLeyenda2.negro,
      valoresLeyenda2.amarillo,
    ];

    const rows = [];
    legend.onAdd = function () {
      color.map((item, index) => {
        rows.push(`
          <div class="legend-row flex gap-2 mb-2 text-bodytext-xs">
            <i class="block rounded-full w-4 h-4 text-bodytext-xs font-secondary" style="background: ${item}"></i>${label[index]}
          </div>`
        );
      });

      let stringleyenda = document.getElementById('ms-leyenda').innerHTML;

      div.innerHTML = '<section class="legend-open"><p class="legend-title">'+stringleyenda+'</p><span class="close-legend"></span>' + rows.join("") + '</section>';

      div.innerHTML += '<section class="legend-collapse"><span class="legend-title">'+stringleyenda+'</span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M8.3341 12.9882L11.3223 10L8.3341 7.01188C8.29343 6.9712 8.25783 6.92737 8.22732 6.88116C8.01375 6.5577 8.04935 6.11813 8.3341 5.83338C8.65952 5.50793 9.18719 5.50793 9.5126 5.83338L13.09 9.41075C13.2463 9.56708 13.3341 9.779 13.3341 10C13.3341 10.2211 13.2463 10.433 13.09 10.5893L9.5126 14.1667C9.18719 14.4922 8.65952 14.4922 8.3341 14.1667C8.00867 13.8413 8.00867 13.3137 8.3341 12.9882Z" fill="#0A253C"/></svg></section>';
      return div;
    };

    jQuery('body').on('click','.close-legend',function(e){
      jQuery('.legend').addClass('closed');
    })

    jQuery('body').on('click','.legend-collapse',function(){
      jQuery('.legend').removeClass('closed');
      jQuery('.legend').removeClass('closed_mobile');

    })

    legend.addTo(map);
    /* legend */
  }

  let searchControl = null;
  function setSearch() {
    if(searchControl != null){
      map.removeControl(searchControl);
    }

    let stringbuscar = document.getElementById('ms-buscar').innerHTML;
    let stringubicacion = document.getElementById('ms-ubicacion').innerHTML;

    searchControl = new L.Control.Search({
      position: "topleft",
      layer: allMarkerClusterGroups,
      container: "searcherMap",
      initial: false,
      collapsed: false,
      textPlaceholder: stringbuscar,
      marker: false,
      textErr: stringubicacion,
      moveToLocation: function(latlng, title, map) {
        searchControl._input.blur();
        document.getElementById('map').focus();

        var marker = markers.find(function(marker) {
          return marker.getLatLng().equals(latlng);
        });

        if (marker) {
          // Abre el modal
          openMarkerModal(marker)
        }
        map.setView(latlng, 7);
      }
    })

    map.addControl(searchControl);
  }

  function openMarkerModal(marker){
    resetIcons(markers);

    if (map.getZoom() == config.minZoom) {
      map.zoomIn();
    }

    var info = marker.getPopup().getContent(); // Obtiene la información del marcador

    document.getElementById("info-div").classList.remove("opacity-0");
    document.getElementById("info-div").classList.add("opacity-1");
    document.getElementById("info-div").innerHTML = info; // Muestra la información en el div

    let icon = "";
    if (marker.temperatura === true) {
      icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/Mark-blue.svg";
    }
    else if (marker.sostenible === true) {
      icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/Mark-green.svg";
    }
    else if (marker.coming === true) {
      icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/Mark-yellow.svg";
    }
    else {
      icon = dirPath + "/wp-content/themes/paack/resources/assets/images/map/Mark-dark.svg";
    }

    marker.setIcon(
      L.icon({
        iconUrl: icon,
        iconSize: 30,
        className: 'selectedMarker',
      })
    );

    jQuery('.legend').addClass('closed_mobile');
    jQuery('#info-div').addClass('marker_show');
  }

  setLegend();
  fetchLocations();

  jQuery('.clear-filters').on('click',function(){
    let inputs = jQuery('#map-filters').find('input[type="checkbox"]');
    inputs.each(function(){
      if(jQuery(this).is(':checked')){
        jQuery(this).trigger('click');
      }
    })
  })
}
